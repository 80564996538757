import { DefaultTheme } from "styled-components"

export const appTheme: DefaultTheme = {
  borderRadius: "10px",

  dimensions: {
    dauto: 'auto',
    dzero: '0',
    d1: '1px',
    d2: '0.125rem',
    d4: '0.25rem',
    d6: '0.375rem',
    d8: '0.5rem',
    d10: '0.625rem',
    d12 : '0.75rem',
    d14: '0.875rem',
    d16: '1rem',
    d20: '1.25rem',
    d24: '1.5rem',
    d28: '1.75rem',
    d32: '2rem',
    d36: '2.25rem',
    d40: '2.5rem',
    d44: '2.75rem',
    d48: '3rem',
    d56: '3.5rem',
    d64: '4rem',
    d80: '5rem',
    d96: '6rem',
    d112: '7rem',
    d128: '8rem',
    d144: '9rem',
    d160: '10rem',
    d176: '11rem',
    d192: '12rem',
    d208: '13rem',
    d224: '14rem',
    d240: '15rem',
    d256: '16rem',
    d288: '18rem',
    d320: '20rem',
    d384: '24rem'
  },

  colors: {
    white: "#f1f1f1",
    grey: '#ddd',
    darkGrey: "#666",
    lightGrey: "#999",
    dirtWhite: "#f6f6f6",
    blue: '#337ab7',
    abBlue: "#0065ab",
    lightBlue: "#c6d7e6",
    black: "#333",
    violet: "#af24e8",
    orange: "#ff8300",
    ligthIndaco: "#1abed9",
    green: "#4db733",
    yellow: "#d3d72c",
    red: "#dc3545",

    links: {
      main: "#81c3f1",
      hover: "#ddd",
      focus: "",
    }
  },


  boxShadow: '0 0 15px -10px #656565',
}