import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import ContractLegend from "../ContractLegend/ContractLegend.component"

interface Props {
    toggle: () => void,
    isOpen: boolean
}

const ContractLegendModal = ({
isOpen,
toggle
}:Props) => {

    return (
        <div className="action-modal">
            <Modal className="status-modal" isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FontAwesomeIcon className="me-2" icon="tag" />
                    Legenda
                </ModalHeader>
                <ModalBody>
                    <ContractLegend />
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ContractLegendModal