import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { useState } from "react"
import { Link } from "react-router-dom"

import { useSelectEntityName } from "../../../../../../pages/providers/ListsPageProvider.provider"
import { useDeleteProposalDataMutation } from "../../../../../../redux/contract/contract.api"
import { getContractStatusIcon, getEntityPath } from "../../../../../../redux/contract/contract.utils"
import { ContractListType } from "../../../../../../redux/list/list.types"
import CanI from "../../../../../auth/CanI.component"
import ButtonWithLoader from "../../../../../ButtonWithLoader/ButtonWithLoader.component"
import DeleteItem from "../../../../../DeleteItemModal/DeleteItemModal.component"
import TdTable from "../../../../TdTable/TdTable.component"

type ProposalTRowProps = {
  item: ContractListType
  sortParam: string
}

const ProposalTRow = ({
  item,
  sortParam,
}: ProposalTRowProps) => {
  const entityName = useSelectEntityName()
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const toggleDelete = () => setDeleteIsOpen(prevState => !prevState)
  
  const [deleteItem, { isLoading: isDeletingItem }] = useDeleteProposalDataMutation()

  return (
    <tr>
      <TdTable
        sortParam={sortParam}
        sortingName="statusId"
        otherClasses={['text-center', 'status']}
      >
        <FontAwesomeIcon
          size="lg" 
          className="mr-2" 
          icon={getContractStatusIcon(item.contractStatusId)} 
          title={item.contractStatusName}
        />
      </TdTable>
      <TdTable 
        value={item.proposalCode}
        sortParam={sortParam}
        sortingName="proposalCode"
      />
      <TdTable value={item.customerType} />
      <TdTable otherClasses={['score-circle-container']}>
        <span 
          title="Cliente affidabile"
          className="score-circle good"
        />
      </TdTable>
      <TdTable value={item.customerName} />
      <TdTable 
        value={item.customerEmail}
        sortParam={sortParam}
        sortingName="customerEmail"
        otherClasses={['break-word']}
      />
      <TdTable 
        value={item.userAgentName}
        sortParam={sortParam}
        sortingName="agent"
      />
      <TdTable 
        value={item.signingDate ? moment(item.signingDate).format('DD-MM-YYYY') : ""}
        sortParam={sortParam}
        sortingName="signingDate"
      />
      <TdTable 
        value={item.insertDate ? moment(item.insertDate).format('DD-MM-YYYY') : ""}
        sortParam={sortParam}
        sortingName="insertDate"
      />
      <TdTable>
        <>
          <span 
            title={`POD validati ${item.validatedPods} su ${item.totalPods}`}
          >
            <strong>POD:</strong> {item.validatedPods}/{item.totalPods}
          </span>
          <br />
          <span 
            title={`PDR validati ${item.validatedPdrs} su ${item.totalPdrs}`}
          >
            <strong>PDR:</strong> {item.validatedPdrs}/{item.totalPdrs}
          </span>
        </>
      </TdTable>
      <TdTable otherClasses={["actions-col"]}>
        <div className="buttons-container">
          <CanI doWhat={"GET"} withPermissions={item.links} entityName={entityName}>
            {({ action }) => (
              <Link
                target="_blank"
                title="Vedi dettaglio"
                className="btn btn-outline-secondary btn-sm mx-1"
                to={`/${getEntityPath(entityName)}/${item.id}/`}
              >
                <FontAwesomeIcon size="sm" icon="eye" />
              </Link>
            )}
          </CanI>
          <CanI doWhat={"DELETE"} withPermissions={item.links} entityName={entityName}>
            {({ action }) => (
              <>
                <ButtonWithLoader
                  size="sm"
                  fontAwesomeIcon={['fas', 'trash-alt']}
                  isLoading={isDeletingItem}
                  outline
                  onClick={toggleDelete}
                  color="danger"
                />
                <DeleteItem
                  isOpen={deleteIsOpen}
                  toggle={toggleDelete}
                  deleteFn={async () => {
                    await deleteItem(action)
                    toggleDelete()
                  }}
                  isDeleting={isDeletingItem}
                />
              </>
            )}
          </CanI>
        </div>
      </TdTable>
    </tr>
  )
}

export default ProposalTRow