import { useState } from "react"

import { useSelectEntityName } from "../../../pages/providers/ListsPageProvider.provider"
import { useFetchListDataQuery, useFetchListFiltersQuery } from "../../../redux/list/list.api"
import WithSkeleton from "../../WithSkeleton/WithSkeleton.component"
import ContractLegend from "../ContractLegend/ContractLegend.component"
import { buildExcelExportUrl, buildFetchUrl, buildPdpExcelExportUrl } from "./utils"
import ProposalFilters from "./ProposalFilters/ProposalFilters.component"
import ProposalTable from "./ProposalTable/ProposalTable.component"
import { LIST_DEFAULT_ITEMS_PER_PAGE } from "../utils"
import TableFooter from "../TableFooter/TableFooter.component"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import { Button, Collapse } from "reactstrap"
import ButtonWithToggle from "../ButtonWithToggle/ButtonWithToggle.component"
import { useMediaQuery } from "react-responsive"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ContractLegendModal from "../ContractLegendModal/ContractLegendModal.component"

const ProposalFiltersWithSkeleton = WithSkeleton(ProposalFilters)
const ProposalTableWithSkeleton = WithSkeleton(ProposalTable)

const ProposalListContainer = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 640px)' })
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(prevState => !prevState)
  const entityName = useSelectEntityName()
  //Handle sorting, filters and selected page
  const [sortParam, setSortParam] = useState('-insertDate')
  const [filters, setFilters] = useState("")
  const [page, setPage] = useState(1)

  //Fetch filters
  const { 
    data: filtersFields, 
    isFetching: isFetchingFilters, 
    isLoading: isLoadingFilters, 
  } = useFetchListFiltersQuery({ entityName })
  const fetchingFilters = isFetchingFilters || isLoadingFilters

  //Fetch list data trigger
  const {
    data: listData,
    isFetching: isFetchingListData,
    isLoading: isLoadingListData, 
    refetch,
  } = useFetchListDataQuery({
    apiUrl: buildFetchUrl(entityName),
    sortParam,
    filters,
    page
  })

  return (
    <section className="list-container">
      <div className="d-flex gap-3 justify-content-end">
        <ButtonWithToggle toggle={toggle} isOpen={isOpen} title="filtri" icon="filter"/>
        <ShowOnCondition showWhen={!isDesktop}>
          <Button 
            outline
            color="secondary"
            style={{ marginBottom: '1rem' }}
            onClick={() => setIsModalOpen(true)}
          >
            <FontAwesomeIcon icon="tag" className="me-2" />
            Legenda
          </Button>
          <ContractLegendModal 
            isOpen={isModalOpen}
            toggle={toggleModal}
          />
        </ShowOnCondition>
      </div>
      <Collapse isOpen={isOpen}>
        <ProposalFiltersWithSkeleton
          isLoading={fetchingFilters}
          selectedFilters={filters}
          setFilters={setFilters}
          fieldsValues={filtersFields}
          refetch={refetch}
          setPage={setPage}
        />
      </Collapse>
      <ShowOnCondition showWhen={isDesktop}>
        <ContractLegend />
      </ShowOnCondition>
      <ProposalTableWithSkeleton 
        isLoading={isLoadingListData}
        isFetching={isFetchingListData}
        sortParam={sortParam}
        setSortParam={setSortParam}
        page={page}
        filters={filters}
        listData={listData}
      />
      <ShowOnCondition showWhen={listData?.count > 0}>
        <TableFooter 
          listData={listData}
          page={page}
          setPage={setPage}
          itemsPerPage={LIST_DEFAULT_ITEMS_PER_PAGE}
          buildExcelExportUrl={buildExcelExportUrl}
          buildPdpExcelExportUrl={buildPdpExcelExportUrl}
        />
      </ShowOnCondition>
    </section>
  )
}

export default ProposalListContainer