import { createGlobalStyle } from "styled-components"

const TableStyles = createGlobalStyle` 
  .legend {
    ul {
      list-style: none;

      li {
        color: ${({ theme }) => theme.colors.blue};
        font-size: 0.875rem;
        line-height: 1.75rem;
      }
    }

    @media (min-width: 768px) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;

      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
        margin: 0;

        li {          
          margin-left: 1rem;          
          font-size: .75rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  .table {
    transition: opacity .3s ease;

    &.is-fetching {
      opacity: .5;
    }

    thead th {
      font-size: .7rem;
      color: ${({ theme }) => theme.colors.blue};
      text-transform: uppercase;
      padding: .75rem .5rem;
      font-weight: 600;
      border-bottom-color: ${({ theme }) => theme.colors.blue};

      &:first-child {
        text-align: center;
      }

      &.sortable-column {
        cursor: pointer;
      }

      &.active-col {
        background-color: rgba(51,122,183,.7);
        color: #f1f1f1;
      }
    }

    tbody td {
      color: ${({ theme }) => theme.colors.black};
      font-size: .75rem;
      padding: .75rem .35rem;
      vertical-align: middle;
      font-weight: 400;
      border-bottom: none;
      border-top: 1px solid ${({ theme }) => theme.colors.grey};

      &.active-col {
        background-color: rgba(51,122,183,.7);
        color: #f1f1f1;
      }

      &.break-word {
        word-break: break-all;
      }

      .communication-actions-container {
        button {
          color: ${({ theme }) => theme.colors.grey};
          border: none;
          
          &:hover,
          &:active {
            color: ${({ theme }) => theme.colors.lightGrey};
          }

          &.active {
            color: ${({ theme }) => theme.colors.blue};
          }
        }
      }

      .buttons-container {
        display: flex;
        align-items: center;

        button {
          margin: .25rem .15rem !important;
        }
      }
    }
  }

  .score-circle-container {
    text-align: center;
  }

  .score-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid $white;
    margin-right: .25rem;
  
    &.bad {
      background-color: ${({ theme }) => theme.colors.red};
    }
  
    &.good {
      background-color: ${({ theme }) => theme.colors.green};
    }
  
    &.ongoing {
      background-color: ${({ theme }) => theme.colors.yellow};
    }
  
    &.unknown {
      background-color: ${({ theme }) => theme.colors.grey};
    }
  }  

  .pagination {
    display: flex;
    flex-wrap: wrap;

    li {
      color: ${({ theme }) => theme.colors.blue};

      a {
        font-size: .75rem;
        padding: .5rem 1rem;
        border: 1px solid #dee2e6;
        margin-left: -1px;
        color: ${({ theme }) => theme.colors.blue};

        &:hover,
        &:active {
          background-color: ${({ theme }) => theme.colors.grey};
        }
      }

      &.selected a {
        background-color: ${({ theme }) => theme.colors.blue};
        color: ${({ theme }) => theme.colors.white};

        &:hover,
        &:active {
          color: ${({ theme }) => theme.colors.white};
        }
      }

      &.disabled a {
        opacity: .5;
      }

      &.next a {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      &.previous a {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        margin-left: 0;
      }
    }
  }

  .total-items {
    font-size: .75rem;
    font-weight: 600;
    margin-bottom: 0;
    text-align: right;

    strong {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`

export default TableStyles