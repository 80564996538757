import ThTable from "../../../ThTable/ThTable.component"

type ContractTHeaderProps = {
  sortParam: string
  setSortParam: (value: string) => void
  page: number
  filters: string
}

const ContractTHeader = (props: ContractTHeaderProps) => {
  return (
    <thead>
      <tr>
        <ThTable label="Status" />
        <ThTable label="Valutazione" />
        <ThTable label="Nome" />
        <ThTable label="Codice fiscale" />
        <ThTable label="P.IVA" />
        <ThTable label="Ccnl" />
        <ThTable label="Qualifica" />
        <ThTable label="Nome agente" />
        <ThTable 
          isSortable
          sortingName="insertDate"
          label="Data caricamento"
          {...props}
        />
        <ThTable 
          isSortable
          sortingName="signingDate"
          label="Data firma"
          {...props}
        />
        <ThTable />
      </tr>
    </thead>
  )
}

export default ContractTHeader