import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMediaQuery } from 'react-responsive'
import ShowOnCondition from '../../auth/ShowOnCondition.component'

const ContractLegend = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 640px)' })
  
  return(
    <div className="legend">
      <ul>
        <ShowOnCondition showWhen={isDesktop}>
          <li className="title">Legenda:</li>
        </ShowOnCondition>
        <li>
          <FontAwesomeIcon icon="door-open" size="lg" />{' '}
          In inserimento
        </li>
        <li>
          <FontAwesomeIcon icon="signature" size="lg" />{' '}
          Inserito
        </li>
        <li>
          <FontAwesomeIcon icon="gears" size="lg" />{' '}
          In lavorazione
        </li>
        <li>
          <FontAwesomeIcon icon="hand" size="lg" />{' '}
          Bloccato
        </li>
        <li>
          <FontAwesomeIcon icon="circle-half-stroke" size="lg" className="parz" />{' '}
          Sincronizzato parz.
        </li>
        <li>
          <FontAwesomeIcon icon="arrows-rotate" size="lg" />{' '}
          Sincronizzato
        </li>
        <li>
          <FontAwesomeIcon icon="circle-xmark" size="lg" />{' '}
          K.O.
        </li>
      </ul>
    </div>
  )
}

export default ContractLegend
