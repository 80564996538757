import { IconName } from "@fortawesome/fontawesome-common-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "reactstrap"

interface Props {
    toggle?: () => void,
    isOpen?: boolean,
    title: string,
    icon: IconName
}
const ButtonWithToggle = ({
    toggle,
    isOpen,
    title,
    icon
}:Props) => {

    return (
      <Button 
        className="ab-button"
        onClick={toggle} 
        style={{ marginBottom: '1rem' }}
      >
        <FontAwesomeIcon icon={icon} className="me-2" />
        {isOpen ? "Nascondi" : "Mostra"} {title}
      </Button>
    )
}

export default ButtonWithToggle